import React from 'react'
import { Box, Typography } from '@mui/material'
import { User } from 'firebase/auth'
import useLoginForm from './useLoginForm'
import { DisplayedForm, LoginFormProps } from './loginFormTypes'
import { InputType } from '../GridForm/InputTypes'
import GridForm from '../GridForm/GridForm'
import { flockTheme } from '../../theme'
import RegisterMultifactorForm from './RegisterMultifactorForm/RegisterMultifactorForm'

const LoginForm = (props: LoginFormProps) => {
  const presentationalProps = useLoginForm(props)
  const {
    onLogin,
    onConfirmSMSCode,
    loading,
    applicationName,
    error,
    backgroundColor,
    user,
    displayedForm,
    phoneNumber,
    captchaComplete,
  } = presentationalProps

  return (
    <Box
      display="flex"
      width="100%"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: backgroundColor || flockTheme.palette.green0.main,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="16px"
        maxWidth="400px"
        gap="16px"
      >
        {displayedForm === DisplayedForm.SMS_CODE ? (
          <>
            <Typography variant="h3">Log in to {applicationName}</Typography>
            <Typography variant="p2">
              Please enter the code we sent to {phoneNumber}
            </Typography>
            <GridForm
              loading={loading}
              onSubmit={onConfirmSMSCode}
              gridProps={{
                gap: '16px',
              }}
              inputConfigs={[
                {
                  name: 'code',
                  type: InputType.Text,
                  required: true,
                  props: {
                    label: 'Verification Code',
                    fullWidth: true,
                  },
                },
                {
                  name: 'errorMsg',
                  type: InputType.CustomComponent,
                  props: {
                    component: (
                      <>
                        {error && (
                          <Typography
                            sx={{ fontFamily: 'Outfit' }}
                            variant="body2"
                            color="error"
                          >
                            {error}
                          </Typography>
                        )}
                      </>
                    ),
                  },
                },
              ]}
            />
          </>
        ) : (
          <>
            {displayedForm === DisplayedForm.REGISTER_SMS ? (
              <RegisterMultifactorForm user={user as User} />
            ) : (
              <>
                <Typography variant="h3">
                  Log in to {applicationName}
                </Typography>
                <GridForm
                  loading={loading}
                  onSubmit={onLogin}
                  ctaButtonProps={{
                    disabled: !captchaComplete || loading,
                  }}
                  gridProps={{
                    gap: '16px',
                  }}
                  inputConfigs={[
                    {
                      name: 'email',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label: 'Email',
                        format: 'email',
                        fullWidth: true,
                      },
                    },
                    {
                      name: 'password',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label: 'Password',
                        type: 'password',
                        fullWidth: true,
                      },
                    },
                    {
                      name: 'forgotPassword',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography
                            color="green4.main"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.location.href = '/send-password-reset'
                            }}
                          >
                            Forgot password?
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'captcha',
                      type: InputType.CustomComponent,
                      props: {
                        component: <Box id="recaptcha-container-id" />,
                      },
                    },
                    {
                      name: 'errorMsg',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <>
                            {error && (
                              <Typography
                                sx={{ fontFamily: 'Outfit' }}
                                variant="body2"
                                color="error"
                              >
                                {error}
                              </Typography>
                            )}
                          </>
                        ),
                      },
                    },
                  ]}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default LoginForm
