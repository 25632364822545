import React from 'react'
import { Box, Typography } from '@mui/material'
import { InputType } from '../../GridForm/InputTypes'
import GridForm from '../../GridForm/GridForm'
import useRegisterMultifactorForm from './useRegisterMultifactorForm'
import { RegisterMultifactorFormProps } from './registerMultifactorFormTypes'

const RegisterMultifactorForm = (props: RegisterMultifactorFormProps) => {
  const {
    loading,
    error,
    showEnterMultifactorCode,
    phoneNumber,
    captchaComplete,
    onEnrollPhone,
    onConfirmSMSCode,
  } = useRegisterMultifactorForm(props)

  return (
    <>
      {showEnterMultifactorCode ? (
        <>
          <Typography variant="h3">Secure Your Account</Typography>
          <Typography variant="p2">
            Please enter the code we sent to {phoneNumber}
          </Typography>
          <GridForm
            loading={loading}
            onSubmit={onConfirmSMSCode}
            gridProps={{
              gap: '16px',
            }}
            inputConfigs={[
              {
                name: 'code',
                type: InputType.Text,
                required: true,
                props: {
                  label: 'Verification Code',
                  fullWidth: true,
                },
              },
              {
                name: 'errorMsg',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <>
                      {error && (
                        <Typography
                          sx={{ fontFamily: 'Outfit' }}
                          variant="body2"
                          color="error"
                        >
                          {error}
                        </Typography>
                      )}
                    </>
                  ),
                },
              },
            ]}
          />
        </>
      ) : (
        <>
          <Typography variant="h3">Secure Your Account</Typography>
          <Typography variant="p2">
            Enter your phone number to which we can send a 6-digit code. Message
            and data rates may apply.
          </Typography>
          <GridForm
            ctaButtonProps={{
              disabled: !captchaComplete,
            }}
            loading={loading}
            onSubmit={onEnrollPhone}
            gridProps={{
              gap: '16px',
            }}
            inputConfigs={[
              {
                name: 'phone',
                type: InputType.Text,
                required: true,
                props: {
                  label: 'Phone Number',
                  format: 'phone',
                  fullWidth: true,
                },
              },
              {
                name: 'catcha',
                type: InputType.CustomComponent,
                props: {
                  component: <Box id="recaptcha-container-id" />,
                },
              },
              {
                name: 'errorMsg',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <>
                      {error && (
                        <Typography
                          sx={{ fontFamily: 'Outfit' }}
                          variant="body2"
                          color="error"
                        >
                          {error}
                        </Typography>
                      )}
                    </>
                  ),
                },
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default RegisterMultifactorForm
