import { useEffect, useState } from 'react'
import { prettyToInternationalPhoneNumber, useFirebaseAuth } from '@flock/utils'
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth'
import {
  RegisterMultifactorFormProps,
  RegisterMultifactorFormPresentationalProps,
  EnrollPhoneResult,
} from './registerMultifactorFormTypes'
import { ConfirmSMSCodeResult } from '../loginFormTypes'

const useRegisterMultifactorForm: (
  props: RegisterMultifactorFormProps
) => RegisterMultifactorFormPresentationalProps = (
  props: RegisterMultifactorFormProps
) => {
  const { user } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showEnterMultifactorCode, setShowEnterMultifactorCode] =
    useState(false)
  const [verificationId, setVerificationId] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [captchaComplete, setCaptchaComplete] = useState(false)
  const [captchaVerifier, setCaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null)

  const { auth } = useFirebaseAuth()

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container-id',
      {
        size: 'normal',
        callback: () => {
          setCaptchaComplete(true)
        },
      }
    )
    recaptchaVerifier.render()
    setCaptchaVerifier(recaptchaVerifier)
  }, [auth])

  const onEnrollPhone = async ({ phone }: EnrollPhoneResult) => {
    setLoading(true)
    setError('')

    try {
      const formattedPhone = prettyToInternationalPhoneNumber(phone)
      const session = await multiFactor(user).getSession()
      const phoneInfoOptions = {
        phoneNumber: formattedPhone,
        session,
      }

      const phoneAuthProvider = new PhoneAuthProvider(auth)
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        captchaVerifier as RecaptchaVerifier
      )
      setPhoneNumber(formattedPhone)
      setVerificationId(newVerificationId)
      setShowEnterMultifactorCode(true)
    } catch (e) {
      setError('A network error has occurred')
    }
    setLoading(false)
  }

  const onConfirmSMSCode = async ({ code }: ConfirmSMSCodeResult) => {
    setLoading(true)
    setError('')
    const cred = PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    try {
      await multiFactor(user).enroll(multiFactorAssertion, 'phone')
      window.location.href = '/app/home-ops'
    } catch (e) {
      setError('Invalid code, please try again.')
    }
    setLoading(false)
  }

  return {
    ...props,
    loading,
    error,
    showEnterMultifactorCode,
    phoneNumber,
    captchaComplete,
    onEnrollPhone,
    onConfirmSMSCode,
  }
}

export default useRegisterMultifactorForm
