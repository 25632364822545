import { useEffect, useState } from 'react'
import {
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth'
import { AuthRole, useFirebaseAuth } from '@flock/utils'
import {
  LoginFormProps,
  LoginFormPresentationalProps,
  ConfirmSMSCodeResult,
  LoginFormResult,
  DisplayedForm,
} from './loginFormTypes'

const useLoginForm: (props: LoginFormProps) => LoginFormPresentationalProps = (
  props
) => {
  const { disableMultifactor } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [user, setUser] = useState<User | null>(null)
  const [displayedForm, setDisplayedForm] = useState(
    DisplayedForm.USER_PASSWORD
  )

  const [verificationId, setVerificationId] = useState('')
  const [captchaComplete, setCaptchaComplete] = useState(false)
  const [captchaVerifier, setCaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null)
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null)
  const [phoneNumber, setPhoneNumber] = useState('')

  const { authorizedRoles, auth } = useFirebaseAuth()

  useEffect(() => {
    if (auth) {
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container-id',
        {
          size: 'normal',
          callback: () => {
            setCaptchaComplete(true)
          },
        }
      )
      recaptchaVerifier.render()
      setCaptchaVerifier(recaptchaVerifier)
    }
  }, [auth])

  const onLogin = async ({ email, password }: LoginFormResult) => {
    setLoading(true)
    setError('')
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      setUser(userCredentials.user)

      const idTokenResult = await userCredentials.user.getIdTokenResult()
      const tokenUserRoles: AuthRole[] = []
      Object.entries(idTokenResult.claims).forEach(([key, value]) => {
        if (
          Object.values(AuthRole).includes(key as AuthRole) &&
          value === true
        ) {
          tokenUserRoles.push(key as AuthRole)
        }
      })

      if (
        !tokenUserRoles.some((role) =>
          authorizedRoles.includes(role as AuthRole)
        )
      ) {
        setError('You do not have permission to access this application.')
        setTimeout(() => {
          window.location.reload()
        }, 10000)
      } else if (
        disableMultifactor ||
        tokenUserRoles.includes(AuthRole.SKIP_MFA)
      ) {
        // check the query params for a redirect
        const urlParams = new URLSearchParams(window.location.search)
        const redirect = urlParams.get('continue')
        if (redirect) {
          window.location.href = redirect
        } else {
          window.location.href = '/app/'
        }
      } else {
        setLoading(false)
        setDisplayedForm(DisplayedForm.REGISTER_SMS)
      }
    } catch (e: any) {
      setLoading(false)
      if (
        e.code === 'auth/user-not-found' ||
        e.code === 'auth/wrong-password'
      ) {
        setError('Invalid email or password')
      } else if (e.code === 'auth/multi-factor-auth-required') {
        const newResolver = getMultiFactorResolver(auth, e)
        const phoneInfoOptions = {
          multiFactorHint: newResolver.hints[0],
          session: newResolver.session,
        }
        const phoneAuthProvider = new PhoneAuthProvider(auth)
        const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          captchaVerifier as RecaptchaVerifier
        )

        setPhoneNumber(
          (newResolver?.hints[0] as PhoneMultiFactorInfo).phoneNumber
        )
        setResolver(newResolver)
        setVerificationId(newVerificationId)
        setDisplayedForm(DisplayedForm.SMS_CODE)
      } else {
        setError('A network error has occurred.')
      }
    }
  }

  const onConfirmSMSCode = async ({ code }: ConfirmSMSCodeResult) => {
    setLoading(true)
    setError('')
    const cred = PhoneAuthProvider.credential(verificationId, code)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
    try {
      await resolver?.resolveSignIn(multiFactorAssertion)
      // check the query params for a redirect
      const urlParams = new URLSearchParams(window.location.search)
      const redirect = urlParams.get('continue')
      if (redirect) {
        window.location.href = redirect
      } else {
        window.location.href = '/app/'
      }
    } catch (e) {
      setError('Invalid code, please try again.')
    }
    setLoading(false)
  }

  return {
    ...props,
    onLogin,
    onConfirmSMSCode,

    loading,
    error,
    user,
    displayedForm,
    phoneNumber,
    captchaComplete,
  }
}

export default useLoginForm
