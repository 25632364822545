import { User } from 'firebase/auth'

export type LoginFormResult = {
  email: string
  password: string
}

export type ConfirmSMSCodeResult = {
  code: string
}

export enum DisplayedForm {
  USER_PASSWORD,
  REGISTER_SMS,
  SMS_CODE,
}

// Props that are used by the useLoginForm hook
export type LoginFormProps = {
  applicationName: string
  disableMultifactor?: boolean
  backgroundColor?: string
}

// Props that are used by the presentational components (LoginFormDesktop.tsx, LoginFormMobile.tsx, LoginFormTablet.tsx)
export type LoginFormPresentationalProps = LoginFormProps & {
  onLogin: (result: LoginFormResult) => void
  onConfirmSMSCode: (result: ConfirmSMSCodeResult) => void

  loading: boolean
  error: string
  user: User | null
  displayedForm: DisplayedForm
  phoneNumber: string
  captchaComplete: boolean
}
